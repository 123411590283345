// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".X5HFns_31nPnaMUvBjwW[data-disabled] {\n    color: var(--ai-color);\n    background: var(--ai-bg);\n    opacity: 0.5;\n  }\n", "",{"version":3,"sources":["webpack://./jsapp/js/theme/kobo/ActionIcon.module.css"],"names":[],"mappings":"AACE;IACE,sBAAsB;IACtB,wBAAwB;IACxB,YAAY;EACd","sourcesContent":[".root {\n  &[data-disabled] {\n    color: var(--ai-color);\n    background: var(--ai-bg);\n    opacity: 0.5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "X5HFns_31nPnaMUvBjwW"
};
export default ___CSS_LOADER_EXPORT___;
