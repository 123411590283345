// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "table.SimpleTable-module__SimpleTableRoot--qSiU3{background-color:var(--mantine-color-gray-9);border-collapse:separate;border-radius:var(--mantine-radius-md)}thead.SimpleTable-module__SimpleTableThead--qRTwE{background-color:var(--mantine-color-gray-8)}th.SimpleTable-module__SimpleTableTh--N2125{font-size:var(--mantine-font-size-sm);color:var(--mantine-color-gray-2);font-weight:400}td.SimpleTable-module__SimpleTableTd--C8fdn{font-size:var(--mantine-font-size-md);border-top-width:1px;border-top-color:var(--mantine-color-gray-7);border-top-style:solid}", "",{"version":3,"sources":["webpack://./jsapp/js/components/common/SimpleTable.module.scss"],"names":[],"mappings":"AAGA,iDACE,4CAAA,CACA,wBAAA,CACA,sCAAA,CAGF,kDACE,4CAAA,CAGF,4CACE,qCAAA,CACA,iCAAA,CACA,eAAA,CAGF,4CACE,qCAAA,CACA,oBAAA,CACA,4CAAA,CACA,sBAAA","sourcesContent":["// We need bigger specificity in each selector to ensure we do overwrite default\n// styles\n\ntable.SimpleTableRoot {\n  background-color: var(--mantine-color-gray-9);\n  border-collapse: separate;\n  border-radius: var(--mantine-radius-md);\n}\n\nthead.SimpleTableThead {\n  background-color: var(--mantine-color-gray-8);\n}\n\nth.SimpleTableTh {\n  font-size: var(--mantine-font-size-sm);\n  color: var(--mantine-color-gray-2);\n  font-weight: 400;\n}\n\ntd.SimpleTableTd {\n  font-size: var(--mantine-font-size-md);\n  border-top-width: 1px;\n  border-top-color: var(--mantine-color-gray-7);\n  border-top-style: solid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SimpleTableRoot": "SimpleTable-module__SimpleTableRoot--qSiU3",
	"SimpleTableThead": "SimpleTable-module__SimpleTableThead--qRTwE",
	"SimpleTableTh": "SimpleTable-module__SimpleTableTh--N2125",
	"SimpleTableTd": "SimpleTable-module__SimpleTableTd--C8fdn"
};
export default ___CSS_LOADER_EXPORT___;
