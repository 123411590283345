// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TAF4UnYuU5828qar1lj2 {\n  padding: 0;\n  box-shadow: 0 0 6px var(--mantine-color-gray-4);\n  border: none;\n}\n\n._afkzIoaXS7SxLbEQsDT {\n  padding: var(--mantine-spacing-sm);\n  color: var(--mantine-color-blue-4);\n\n  font-size: var(--mantine-font-size-md);\n}\n\n._afkzIoaXS7SxLbEQsDT[data-hovered] {\n    color: var(--mantine-color-blue-5);\n    background-color: transparent;\n  }\n\n._afkzIoaXS7SxLbEQsDT[data-variant='danger'] {\n    color: var(--mantine-color-red-5);\n  }\n\n._afkzIoaXS7SxLbEQsDT[data-variant='danger'][data-hovered] {\n      color: var(--mantine-color-red-6);\n    }\n\n.tUekA22k4KZhLwyPmgw6 {\n  border-color: var(--mantine-color-gray-6);\n  margin: 0;\n}\n", "",{"version":3,"sources":["webpack://./jsapp/js/theme/kobo/Menu.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,+CAA+C;EAC/C,YAAY;AACd;;AAEA;EACE,kCAAkC;EAClC,kCAAkC;;EAElC,sCAAsC;AAcxC;;AAZE;IACE,kCAAkC;IAClC,6BAA6B;EAC/B;;AAEA;IACE,iCAAiC;EAKnC;;AAHE;MACE,iCAAiC;IACnC;;AAIJ;EACE,yCAAyC;EACzC,SAAS;AACX","sourcesContent":[".dropdown {\n  padding: 0;\n  box-shadow: 0 0 6px var(--mantine-color-gray-4);\n  border: none;\n}\n\n.item {\n  padding: var(--mantine-spacing-sm);\n  color: var(--mantine-color-blue-4);\n\n  font-size: var(--mantine-font-size-md);\n\n  &[data-hovered] {\n    color: var(--mantine-color-blue-5);\n    background-color: transparent;\n  }\n\n  &[data-variant='danger'] {\n    color: var(--mantine-color-red-5);\n\n    &[data-hovered] {\n      color: var(--mantine-color-red-6);\n    }\n  }\n}\n\n.divider {\n  border-color: var(--mantine-color-gray-6);\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "TAF4UnYuU5828qar1lj2",
	"item": "_afkzIoaXS7SxLbEQsDT",
	"divider": "tUekA22k4KZhLwyPmgw6"
};
export default ___CSS_LOADER_EXPORT___;
