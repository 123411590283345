// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._qjz7yeurIivrXeS5LAU {\n  padding: 12px 24px;\n}\n\n  ._qjz7yeurIivrXeS5LAU:not(:last-child) {\n    margin-bottom: 24px;\n  }\n\n  ._qjz7yeurIivrXeS5LAU:not(:first-child) {\n    margin-top: 24px;\n  }\n\n.FpqH7ckl6PjekEyfIdcy {\n  font-size: var(--mantine-font-size-md);\n  color: var(--mantine-color-gray-1);\n}\n", "",{"version":3,"sources":["webpack://./jsapp/js/theme/kobo/Alert.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AASpB;;EAPE;IACE,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;EAClB;;AAGF;EACE,sCAAsC;EACtC,kCAAkC;AACpC","sourcesContent":[".root {\n  padding: 12px 24px;\n\n  &:not(:last-child) {\n    margin-bottom: 24px;\n  }\n\n  &:not(:first-child) {\n    margin-top: 24px;\n  }\n}\n\n.message {\n  font-size: var(--mantine-font-size-md);\n  color: var(--mantine-color-gray-1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_qjz7yeurIivrXeS5LAU",
	"message": "FpqH7ckl6PjekEyfIdcy"
};
export default ___CSS_LOADER_EXPORT___;
