// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".si3BVAb31Q6EJ6Xq6hWE {\n  color: var(--mantine-color-gray-4);\n  background-color: transparent;\n}\n\n  .si3BVAb31Q6EJ6Xq6hWE:hover {\n    color: var(--mantine-color-gray-2);\n    background-color: transparent;\n  }\n\n.z6e_76DHa_xlkgVmhUPs {\n  font-size: var(--mantine-font-size-xl);\n  font-weight: var(--mantine-heading-font-weight);\n  color: var(--mantine-color-gray-0);\n}\n", "",{"version":3,"sources":["webpack://./jsapp/js/theme/kobo/Modal.module.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,6BAA6B;AAM/B;;EAJE;IACE,kCAAkC;IAClC,6BAA6B;EAC/B;;AAGF;EACE,sCAAsC;EACtC,+CAA+C;EAC/C,kCAAkC;AACpC","sourcesContent":[".close {\n  color: var(--mantine-color-gray-4);\n  background-color: transparent;\n\n  &:hover {\n    color: var(--mantine-color-gray-2);\n    background-color: transparent;\n  }\n}\n\n.title {\n  font-size: var(--mantine-font-size-xl);\n  font-weight: var(--mantine-heading-font-weight);\n  color: var(--mantine-color-gray-0);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"close": "si3BVAb31Q6EJ6Xq6hWE",
	"title": "z6e_76DHa_xlkgVmhUPs"
};
export default ___CSS_LOADER_EXPORT___;
